import React, { Component } from 'react';
import { CustomCheckbox } from './CustomCheckbox'
import '../index.css'
import { FormControlLabel, withStyles } from '@material-ui/core'

export const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '0.95rem'
  }
})(props => <FormControlLabel color="default" {...props} />);

export const CheckboxLabel = (props) => {
  return <CustomFormControlLabel control={
            <CustomCheckbox checked={props.checked} onChange={props.onChange} />
          } label={props.label} />
}
