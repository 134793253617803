import React, { Component } from 'react';
import '../index.css';

export class InputWrapper extends Component {

  render() {
    let { label, children } = this.props;

    return (
      <div className='single-select-container single-select-input'>
        <div className="single-select-label">{label}</div>
        {children}
      </div>
    );
  }
}
