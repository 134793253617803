import React, {Component} from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import "../index.css";
import {InputLabel} from './InputLabel'

export class LabelSearchBox extends Component {

  timeout = null;

  handleChanges = (event) => {
    clearTimeout(this.timeout);

    let value = event.target.value;
    this.timeout = setTimeout(() => {
      this.props.onChange && this.props.onChange(value)
    }, 500);
  }

  render() {
    let {placeholder, classes, label, rootClasses} = this.props;

    let searchBoxClasses = 'search-box ';
    if (classes) {
      searchBoxClasses = searchBoxClasses + classes;
    }

    return (
      <div className={rootClasses}>
        <InputLabel text={label} />
        <div className={searchBoxClasses}>
          <div className='search-box-icon'>
            <SearchIcon
              alt='busca'
            />
          </div>
          <InputBase
            placeholder={placeholder}
            classes={{
              root: 'search-box-input-root',
              input: 'search-box-input-input'
            }}
            inputProps={{ 'aria-label': 'Search' }}
            onChange={this.handleChanges}
          />
        </div>
      </div>
    )
  }

}
