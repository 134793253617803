import React, { Component } from 'react';
import {CircularProgress} from "@material-ui/core";

export class Loading extends Component {

  render() {
    let { classes } = this.props;

    return (
      <div className={`list-loader-container ${classes}`}>
        <CircularProgress
          className='list-loader-icon'
        />
      </div>
    );
  }
}