import React, { Component } from 'react';
import './index.css';

export class EmptyTable extends Component {

  render() {
    let { message } = this.props;

    return (
      <div className={`empty-table-container`}>
        <div>{message}</div>
      </div>
    );
  }
}