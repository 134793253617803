import axios from 'axios'

export const TOKEN_KEY = 'APP_TOKEN';

export function getAxios(platform, csv) {
  const prefix = platform === 'mobile' ? '' : 'Bearer ';
  const headers =  {
    'Authorization': prefix + localStorage.getItem(TOKEN_KEY)
  };

  const responseType = csv ? 'blob' : ''
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: headers,
    responseType
  })
}

export const downloadResponse = (response, filename) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}
