import mixpanel from 'mixpanel-browser'
import {TOKEN_KEY} from './NetworkHelper'
const jwtDecode = require('jwt-decode');

let mixpanelToken = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'homolog' ? '82006924ccf737aa0dec5275af9bdb99' : '3523b1fc428b9b93d8a9ef72ca4cbd99';

export const startMixpanel = () => {
  mixpanel.init(mixpanelToken);

  let token = localStorage.getItem(TOKEN_KEY);

  if (token !== undefined && token !== null) {
    let decoded = jwtDecode(token)

    mixpanel.identify(decoded.usr.id);
  }
}

export const mixpanelPeopleSet = (prop) => mixpanel.people.set(prop);

export const mixpanelPeopleSetOnce = (prop) => mixpanel.people.set_once(prop);

export const mixpanelTrack = (event, props) => mixpanel.track(event, props);

export const mixpanelIncrement = (props, value = null) => value != null ? mixpanel.people.increment(props) : mixpanel.people.increment(props, value);
