import React, {Component} from "react";

export class RedBadge extends Component {
  render() {
    return (
      <div className='red-badge-container'>

      </div>
    )
  }
}