import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor/dist/visibility-sensor.min';
import {CircularProgress} from "@material-ui/core";

export class ListLoader extends Component {

  changedVisibility = (isVisible) => {
    if (isVisible && this.props.onShow) {
      this.props.onShow();
    }
  }

  render() {
    return (
      <div className='list-loader-container'>
        <VisibilitySensor onChange={this.changedVisibility}>
          <CircularProgress
            className='list-loader-icon'
          />
        </VisibilitySensor>
      </div>
    );
  }
}