import React, { Component } from 'react';
import { Checkbox, withStyles } from '@material-ui/core';

export const CustomCheckbox = withStyles({
  root: {
    color: "#747474",
    '&$checked': {
      color: "#1075ce",
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);