import React, { Component } from 'react';
import { default as ReactSelect } from "react-select";
import { InputLabel } from './InputLabel'
import '../index.css';

export class SingleSelectInput extends Component {

  render() {
    let { label, options, onChange, selectedOption } = this.props;

    return (
      <div className='single-select-container single-select-input'>
        <InputLabel text={label} />
        <ReactSelect
          {...this.props}
          options={options}
          onChange={onChange}
          styles={{
            control: (base, state) => ({
              ...base,
              border: '1px solid #F5F6F8', // default border color
              boxShadow: 'none', // no box-shadow
              background: '#F5F6F8'
            }),
          }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#1075CE',
            }
          })}
          value={selectedOption}
        />
      </div>
    );
  }
}
