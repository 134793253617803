import React, { Component } from 'react';
import { default as ReactSelect } from "react-select";
import { InputLabel } from './InputLabel'
import '../index.css';

export class MultiSelectInput extends Component {

  isAllOptionsSelected = () => {
    let { selectedOptions, allOption } = this.props;

    return (Array.isArray(selectedOptions)
      && selectedOptions.filter((option) =>
        option.value === allOption.value).length > 0);
  }

  render() {
    let { label, options, onChange, selectedOptions, allOption } = this.props;

    if (this.isAllOptionsSelected()) {
      options = [];
      selectedOptions = [allOption];
    }

    return (
      <div className='single-select-container single-select-input'>
        <InputLabel text={label} />
        <ReactSelect
          {...this.props}
          options={[allOption, ...options]}
          onChange={(selected, event) => {
            if (selected !== null && selected.length > 0) {
              if (selected[selected.length - 1].value === allOption.value) {
                return onChange([allOption, ...options]);
              }
              let result = [];
              if (selected.length === options.length) {
                if (selected.includes(allOption)) {
                  result = selected.filter(
                    option => option.value !== allOption.value
                  );
                } else if (event.action === "select-option") {
                  result = [allOption, ...options];
                }
                return onChange(result);
              }
            }

            return onChange(selected);
          }}
          isMulti
          closeMenuOnSelect={false}
          styles={{
            control: (base, state) => ({
              ...base,
              border: '1px solid #F5F6F8', // default border color
              boxShadow: 'none', // no box-shadow
              background: '#F5F6F8'
            }),
            multiValueLabel: base => ({
              ...base,
              textOverflow: 'unset',
              whiteSpace: 'unset'
            })
          }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#1075CE',
            }
          })}
          value={selectedOptions}
        />
      </div>
    );
  }
}
