import React, { Component } from 'react';
import moment from 'moment'
import './styles.css';
import SetaImg from './images/seta_next.svg';
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
import { CalendarRange } from 'mdi-material-ui';


class MonthSelector extends Component {

  constructor(props) {
    super(props);

    let calendarDate = moment();

    if ( props.month && props.year )
      calendarDate.set({ 'month':  (props.month - 1), 'year': props.year });

    let locale = 'pt-BR';
    if (props.locale) {
      locale = props.locale;
    }
    this.state = {
      currentDate: calendarDate,
      monthString: moment(calendarDate).toDate().toLocaleDateString(locale, {month: 'long'}),
      yearString: moment(calendarDate).toDate().toLocaleDateString(locale, {year: 'numeric'}),
      locale: locale
    };

    // Bind
    this.bindedOnMonthLeft = this.onMonthLeft.bind(this);
    this.bindedOnMonthRight = this.onMonthRight.bind(this);

    this.pickAMonth = React.createRef();
  }

  handleAMonthChange = (year, month) => {
    let newDate = moment().month(month - 1).year(year);
    this.setState({...this.state,
      currentDate: newDate,
      monthString: moment(newDate).toDate().toLocaleDateString(this.state.locale, {month: 'long'}),
      yearString: moment(newDate).toDate().toLocaleDateString(this.state.locale, {year: 'numeric'}),
      month: newDate.get('month'),
      year: newDate.get('year')
    });

    if (this.props.onDateChanged) {
      this.props.onDateChanged(newDate)
    }
  }

  handleAMonthDissmis = () => {

  }

  handleClickMonthBox = () => {
    this.pickAMonth.current.show();
  }



  onMonthLeft() {
    let newDate = this.state.currentDate.add(-1, 'month');
    this.setState({...this.state,
      currentDate: newDate,
      monthString: moment(newDate).toDate().toLocaleDateString(this.state.locale, {month: 'long'}),
      yearString: moment(newDate).toDate().toLocaleDateString(this.state.locale, {year: 'numeric'}),
      month: newDate.get('month'),
      year: newDate.get('year')
    });

    if (this.props.onDateChanged) {
      this.props.onDateChanged(newDate)
    }
  }

  onMonthRight() {
    let newDate = this.state.currentDate.add(1, 'month');
    this.setState({...this.state,
      currentDate: newDate,
      monthString: moment(newDate).toDate().toLocaleDateString(this.state.locale, {month: 'long'}),
      yearString: moment(newDate).toDate().toLocaleDateString(this.state.locale, {year: 'numeric'}),
      month: newDate.get('month'),
      year: newDate.get('year')
    });

    if (this.props.onDateChanged) {
      this.props.onDateChanged(newDate)
    }
  }

  render() {
    let { lang } = this.props;
    let { currentDate } = this.state;

    let pickerLang = {};
    if (lang) {
      pickerLang = lang;
    } else {
      pickerLang = {
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        from: 'De',
        to: 'Até'
      };
    }

    let mvalue = {year: currentDate.year(), month: currentDate.month() + 1};

    return (
      <div className='month-selector-container'>
        <a className='month-selector-arrow'>
          <img className='month-selector-icon' onClick={this.bindedOnMonthLeft} src={SetaImg} />
        </a>
        <div className='month-selector-title'>
          <label className='month-selector-month-title'>{this.state.monthString.charAt(0).toUpperCase() + this.state.monthString.substr(1)}</label>
          <label className='month-selector-year-title'>{this.state.yearString}</label>
        </div>
        <a className='month-selector-arrow'>
          <img className='month-selector-icon-right' onClick={this.bindedOnMonthRight} src={SetaImg} />
        </a>
        <Picker
          ref={this.pickAMonth}
          years={{min: 2010, max: moment().year}}
          value={mvalue}
          lang={pickerLang.months}
          onChange={this.handleAMonthChange}
          onDismiss={this.handleAMonthDissmis}
        >
          <CalendarRange className={'icon-calendar'} onClick={this.handleClickMonthBox} />
        </Picker>
      </div>
    );
  }
}

export default MonthSelector;
