import React, { Component } from 'react';
import { LinearProgress } from "@material-ui/core";
import './index.css'

export class LinearLoading extends Component {

  render() {
    let { classes } = this.props;

    return (
      <div className={`list-loader-container ${classes}`}>
        <LinearProgress variant='query' className={'linear-list-loader'}
        />
      </div>
    );
  }
}
