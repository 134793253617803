import React, { Component } from 'react';
import '../index.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import './react_date_custom.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';
import { InputLabel } from './InputLabel';

const moment = require('moment')

export class DateRangeInput extends Component {

  constructor(props) {
    super(props)

    moment.locale('pt-br')

    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null
    }
  }

  render() {
    let { label, handleChangeDate, startDate, endDate } = this.props;

    return (
      <div className='single-select-container'>
        <InputLabel text={label} />
        <DateRangePicker
          startDateId="startDate"
          endDateId="endDate"
          startDate={startDate}
          endDate={endDate}
          onDatesChange={handleChangeDate}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => { this.setState({ focusedInput })}}
          isOutsideRange={() => false}
          displayFormat={'DD/MM/YYYY'}
        />
      </div>
    );
  }
}
