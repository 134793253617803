import React, {Component} from "react";
import InputBase from "@material-ui/core/InputBase";
import "./inputs.css";
import { InputLabel } from './InputLabel';

export class TextInput extends Component {

  handleChanges = (event) => {
    event.persist();
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.props.handleChange && this.props.handleChange(event.target.value)
    }, 500);
  }

  render() {
    let {label, classes, handleChange, placeholder} = this.props

    return (
      <div className={classes}>
        <InputLabel text={label} />
        <InputBase
          placeholder={placeholder}
          classes={{
            root: 'text-input-root'
          }}
          onChange={this.handleChanges}
        />
      </div>
    )
  }
}
